<template>
  <div class="order">
    <div class="filter">
      <div class="flex_center_col">
        <Page :current="problemListPar.page" :total="taskListTotal" simple @on-change="pageChange" style="margin-left: 10px;margin-right:20px" />
        <el-date-picker v-model="date_stamp" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="timestamp" style="width:200px;margin-right:20px" :clearable="false">
        </el-date-picker>
        <el-select v-model="value1" placeholder="项目数据" style="width:150px;margin-right:20px" @change="handleChange">
          <el-option v-for="item in departmentList" :key="item.index" :label="item.name" :value="item.index">
          </el-option>
        </el-select>
      </div>

      <el-select v-model="problemListPar.type" placeholder="状态选择" style="width:150px;margin-right:20px">
        <el-option v-for="item in statusArr" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-select v-model="problemListPar.site_id" placeholder="点位选择" style="width:150px;margin-right:20px">
        <el-option v-for="item in siteList" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      <el-button type="primary" style="position: absolute;
  right: 120px;" @click="monthReport=true">导出月报</el-button>
      <el-button type="primary" style="position: absolute;
  right: 6px;" @click="dialogVisible=true">导出记录</el-button>
    </div>
    <div class="list">
      <div class="left">
        <div v-if="problemList.length===0" class="flex_center_col_100">
          暂无列表
        </div>
        <div class="item" v-for="item in problemList" :key="item.id" @click="getInfo(item.id, item.patrol_stamp)" :class="item.id == problemDetail.id?'active_color':''">
          <div class="info">
            <el-avatar icon="el-icon-user-solid" :src="item.patrol_user_avatar" @error="errorHandler" v-if="item.patrol_user_avatar" size="small" style="margin-right:16px">
              <img :src="circleUrl"/>
            </el-avatar>
            <el-avatar icon="el-icon-user-solid" :src="circleUrl" v-else size="small" style="margin-right:16px"></el-avatar>
            <div class="right" :class="item.id == problemDetail.id?'active_color':''">
              <div class="time">
                <div class="name">{{ item.patrol_user_name }}</div>
                <div class="creat_time">
                  {{
                    (item.prescribed_start_stamp * 1000)
                      | dateformat("YYYY-MM-DD HH:mm")
                  }}
                </div>
                <el-button type="info" round v-if="item.patrol_stamp == 0" size="mini">未巡</el-button>
                <el-button type="primary" round v-else-if="item.situation == 1" size="mini">正常</el-button>
                <el-button type="danger" round v-else size="mini">异常</el-button>
              </div>
              <div class="name">{{ item.inspection_name }}</div>
              <div class="location">{{ item.site_name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right" style="height:667px">
        <div v-if="Object.keys(this.problemDetail).length===0 " class="flex_center_col_100">请选择所需查看的巡检项目</div>
        <div v-if="Object.keys(this.problemDetail).length===1 " class="flex_center_col_100">暂无数据</div>
        <div class="item" v-else>
          <!-- <div class="item_title">{{problemDetail.site_name}}</div> -->
          <div class="content" v-for="item in problemDetail.inspection_logs" :key="item.id">
            <div class="title ">
              <span> {{item.inspection_name}}</span>
              <span>
                <el-button type="primary" size="mini" v-if="item.situation == 1" round style="margin-rigth: 20px; margin-left: 30px" plain>正常</el-button>
                <el-button type="danger" size="mini" v-else round style="margin-rigth: 20px; margin-left: 30px">异常</el-button>
              </span>
            </div>
            <div style="background-color: #f5f9ff;border-radius:10px;padding:0 20px 0;margin-top:20px">
              <div v-if="item.inspection.type==4" class="linHeight activeColor">{{item.value}}</div>
              <div class="query linHeight activeColor" v-for="(citem,index1) in item.option_logs" :key="index1" v-if="citem.selected==1">
                <span class="after"></span>{{citem.description}}</div>
              <!-- <div style="height:20px" v-if="item.inspection.type!=4 && item.option_logs.find(citem=>citem.selected==1)"></div> -->
              <div v-if="item.remark" class="linHeight">{{item.remark}}</div>
              <div v-for="media in item.files" :key="media.url" style="display:inline-block;width:100px;height:100px;margin:20px 20px" v-if="media.type==1||media.type==2||media.type==4">
                <el-image style="width: 100px; height: 100px;margin:0 20px" :src="media.thumbnail_url" :preview-src-list="srcList" v-if="media.type==1">
                </el-image>
                <span @click="clickVideo(media.url)" style="
                            position: relative;
                            height: 100px;
                            width: 100px;
                            margin:0 20px;
                            display:inline-block
                          " v-if="media.type == 2">
                  <i class="el-icon-video-play"></i>
                  <video :src="media.url" @click="clickVideoSlef" style="height:100px;width:100px ;border:1px solid #c1c2cb"></video>
                </span>
                <el-image style="width: 100px; height: 100px;margin:0 20px" :src="media.url" :preview-src-list="srcList" v-if="media.type==4">
                </el-image>
              </div>
              <div v-if="media.type == 3" v-for="media in item.files" :key="media.url" style="margin:10px 0">
                <audio :src="media.url" controls="controls"></audio>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popContainer" v-show="videoVisible" @click="closeVideoPage">
      <i class="el-icon-circle-close" @click="closeVideoPage"></i>
      <video class="video-js" controls preload="auto" data-setup="{}" ref="myVideo" style="width: 70vw; height: 80vh" :src="videoSrc"></video>
    </div>
    <!-- <el-dialog title="导出记录" :visible.sync="dialogVisible" width="40%">
      <div class="flex_clo">
        <div>最多可导出31天时长的记录</div>
        <el-date-picker v-model="value2" type="daterange" align="center" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" >
        </el-date-picker>
      </div>
      <span slot="footer"  width="40%" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleQuery">确 定</el-button>
      </span>
    </el-dialog> -->
    <el-dialog title="导出记录" :visible.sync="dialogVisible" width="40%">
      <el-row :gutter="15">
        <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="100px">
          <el-col :span="12">
            <el-form-item label="日期范围" prop="field101">
              <el-date-picker type="daterange" v-model="formData.field101" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :style="{width: '100%'}" start-placeholder="开始日期" end-placeholder="结束日期" range-separator="至" clearable></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="10" style="line-height:28px"> 最多可导出31天时长的记录
          </el-col>
          <el-col :span="13">
            <el-form-item label="导出附件" prop="field102">
              <el-switch v-model="formData.field102"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="6"><a :href="bsUrl + '/system/linkRedirect?key=export_help'" target="_blank"><i class="el-icon-question"></i> 如何打开导出的文件</a></el-col>
          <el-col :span="13">
            <el-form-item label="电子邮箱" prop="field103" v-if="formData.field102">
              <el-input v-model="formData.field103" placeholder="请输入电子邮箱" clearable :style="{width: '100%'}">
              </el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handelConfirm">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="导出月报" :visible.sync="monthReport" width="40%">
      <span style="line-height:28px">仅可选择当前月份之前的月报</span>

      <div class="block">
        <span style="margin-right:20px">月份选择</span>
        <el-date-picker v-model="monthData" type="month" placeholder="选择月">
        </el-date-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeMonth">取 消</el-button>
        <el-button type="primary" @click="handleMonth">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import sort from '../../components/sort';
import {
  organization,
  task,
  problem,
  patrol,
  site,
  uploadUrl,
  bsURl,
} from '../../utils/api';
import loadMore from '../../components/loadMore';
import qs from 'qs';
export default {
  name:'patrol',
  data() {
    return {
      radio: '',
      value1: 0,
      keyword: '',
      date_stamp: '',
      circleUrl:
        'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      problemListPar: {
        date_stamp: new Date(new Date().toLocaleDateString()).getTime() / 1000,
        scope: 2,
        page: 1,
        page_size: 10,
        type: '0',
        site_id: null,
        user_id: '',
        department_id: '',
        keyword: ''
      },
      taskListTotal: 0,
      departmentList: [],
      problemList: [],
      statusArr: [
        {
          label: '全部',
          value: '0'
        },
        {
          label: '已巡检',
          value: '1'
        },
        {
          label: '未巡检',
          value: '2'
        }
      ],
      url:
        'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      srcList: [
        'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
        'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
      ],
      remarkList: [],
      problemDetail: {},
      isLoading: true,
      oprationPar: {
        id: '',
        page: 0,
        page_size: 10
      },
      oprationList: [],
      detailImgList: [],
      videoSrc: '',
      videoVisible: false,
      siteList: [],
      site_logs: [],
      srcList1: [],
      dialogVisible: false,
      value2: '',
      formData: {
        field101: [],
        field102: false,
        field103: ''
      },
      rules: {
        field101: [],
        field103: []
      },
      monthReport: false,
      monthData: '',

      bsUrl: bsURl,
    };
  },
  created() {
    this.date_stamp = new Date(new Date().toLocaleDateString()).getTime();
    this.getDepartmenList();
    this.getProblemList();
    this.getSiteLIst();
  },
  components: { 'load-more': loadMore },
  methods: {
    async getProblemList() {
      const res = await this.$http.post(patrol.history, this.problemListPar);
      this.problemList = res.data;
      this.taskListTotal = res.total;
    },
    // 获取点位列表
    async getSiteLIst() {
      const res = await this.$http.get(site.listSite + '?page=0');
      res.data.unshift({ name: '全部点位', id: null });
      this.siteList = res.data;
    },
    handleChange(value) {
      Object.assign(this.problemListPar, this.departmentList[value].data);
    },
    pageChange(e) {
      this.problemListPar.page = e;
      this.getProblemList();
    },
    async getDepartmenList() {
      const res = await this.$http.get(organization.listDepartment);
      res.data.unshift({
        name: '我的数据',
        data: {
          user_id: this.$store.state.user_info.id,
          scope: 0,
          department_id: null
        }
      });
      res.data.unshift({
        name: '全部数据',
        data: { id: null, department_id: null, scope: 2 }
      });
      res.data.forEach((item, index) => {
        //  item.value={index:index+2,id:item.id,scope:2}
        item.index = index;
        if (index > 1) {
          item.data = { department_id: item.id, scope: 1, id: null };
        }
      });
      this.departmentList = res.data;
    },
    detailKeyword() {
      if (this.problemListPar.keyword === this.keyword) {
        return false;
      }
      this.problemListPar.keyword = this.keyword;
    },
    async getInfo(id, stamp) {
      if (stamp === 0) {
        this.problemDetail = {};
        return (this.problemDetail.id = id);
      }
      if (id === this.problemDetail.id) {
        return false;
      }
      const res = await this.$http.get(
        patrol.siteLogInfo + '?site_log_id=' + id
      );
      this.problemDetail = res.data;
      this.site_logs = res.data;
      this.srcList = [];
      res.data.inspection_logs.forEach(item => {
        item.files.forEach(fitem => {
          if (fitem.type == 1) {
            this.srcList.push(fitem.url);
          }
        });
        if (item.signature) {
          this.srcList.push(item.signature);
          item.files.push({ type: 4, url: item.signature });
        }
      });
      this.oprationPar.id = id;
      this.oprationPar.page = 0;
      this.isLoading = true;
      this.oprationList = [];
      this.detailImgList = [];
    },
    loadmore() {
      this.getOperationList();
    },
    async getOperationList() {
      if (!this.isLoading) {
        return false;
      }
      this.oprationPar.page++;
      const res = await this.$http.get(
        problem.listOperation + '?' + qs.stringify(this.oprationPar)
      );
      res.data.forEach(item => {
        item.files.forEach(cItem => {
          if (cItem.type == 1) {
            this.detailImgList.push(cItem.url);
          }
        });
      });
      this.oprationList.push(...res.data);
      if (res.total == this.oprationList.length) {
        this.isLoading = false;
      }
    },
    clickVideoSlef() {
      window.event ? (window.event.returnValue = false) : e.preventDefault();
    },
    clickVideo(url) {
      window.event ? (window.event.returnValue = false) : e.preventDefault();
      this.$refs.myVideo.src = url;
      this.$refs.myVideo.play();
      this.videoVisible = true;
    },
    closeVideoPage() {
      this.$refs.myVideo.pause();
      this.videoVisible = false;
    },
    async handleQuery() {
      let timestamp = new Date().getTime() / 1000;
      if (this.formData.field101.length === 0) {
        return this.$message.error('请选择时间范围');
      }
      let start_date_stamp =
        new Date(this.formData.field101[0]).getTime() / 1000 - 8 * 60 * 60;
      let end_date_stamp =
        new Date(this.formData.field101[1]).getTime() / 1000 - 8 * 60 * 60;
      if (end_date_stamp - start_date_stamp > 31 * 24 * 3600) {
        return this.$message.error('最多仅可选择31天时间范围');
      }
      if (timestamp < end_date_stamp) {
        return this.$message.error('请选择今天以前的日期');
      }
      const res = await this.$http.get(
        patrol.export +
          '?start_date_stamp=' +
          start_date_stamp +
          '&end_date_stamp=' +
          end_date_stamp
      );
      if (res.code !== 0) {
        return this.$message.error('下载失败');
      }
      if (res.data.url[0] == '/') {
        window.open(uploadUrl + res.data.url);
      } else {
        window.open(res.data.url);
      }
      this.$message.success('下载完成');
      this.dialogVisible = false;
    },
    async handleQuerys() {
      let timestamp = new Date().getTime() / 1000;
      if (this.formData.field101.length === 0) {
        return this.$message.error('请选择时间范围');
      }
      let start_date_stamp =
        new Date(this.formData.field101[0]).getTime() / 1000 - 8 * 60 * 60;
      let end_date_stamp =
        new Date(this.formData.field101[1]).getTime() / 1000 - 8 * 60 * 60;
      if (end_date_stamp - start_date_stamp > 31 * 24 * 3600) {
        return this.$message.error('最多仅可选择31天时间范围');
      }
      if (timestamp < end_date_stamp) {
        return this.$message.error('请选择今天以前的日期');
      }
      if (!this.formData.field103) {
        this.$message.error('请填写邮箱号码');
      }
      let reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (!reg.test(this.formData.field103)) {
        return this.$message.error('邮箱格式有误，请检查邮箱');
      }
      const res = await this.$http.get(
        patrol.exportWithMediaAndEmail +
          '?start_date_stamp=' +
          start_date_stamp +
          '&end_date_stamp=' +
          end_date_stamp +
          '&email=' +
          this.formData.field103
      );
      if (res.code !== 0) {
        return this.$message.error('发送失败');
      } else {
        this.$message.success('发送成功,请稍后前往邮箱查看');
      }
      this.dialogVisible = false;
    },
    onOpen() {},
    onClose() {
      this.$refs['elForm'].resetFields();
    },
    close() {
      this.$emit('update:visible', false);
      this.dialogVisible = false;
    },
    handelConfirm() {
      if (!this.formData.field102) {
        this.handleQuery();
      } else {
        this.handleQuerys();
      }
    },
    async handleMonth() {
      if (!this.monthData) {
        return this.$message.error('请选择月报月份');
      }
      let year = new Date(this.monthData).getFullYear();
      let month = new Date(this.monthData).getMonth() + 1;
      let nowYear = new Date().getFullYear();
      let nowMonth = new Date().getMonth() + 1;
      // this.monthData=''
      console.log(year, nowYear, month, nowMonth);
      if (year > nowYear || (year == nowYear && nowMonth <= month)) {
        return this.$message.error('所选日期超时，请重新选择');
      }
      const res = await this.$http.get(
        patrol.exportOrganizationMonthlyReport +
          '?year=' +
          year +
          '&month=' +
          month
      );
      if (res.data.url[0] == '/') {
        window.open(uploadUrl + res.data.url);
      } else {
        window.open(res.data.url);
      }
      this.closeMonth();
    },
    closeMonth() {
      this.monthData = '';
      this.monthReport = false;
    },
    errorHandler() {
        return true
      }
  },
  computed: {
    problemListPa() {
      return JSON.parse(JSON.stringify(this.problemListPar));
    }
  },
  watch: {
    problemListPa: {
      handler: function(newValue, oldValue) {
        if (newValue.page == oldValue.page) {
          this.problemListPar.keyword = this.keyword;
          this.problemListPar.page = 1;
          this.getProblemList();
        } else {
        }
      },
      deep: true
    },
    date_stamp(value) {
      this.problemListPar.date_stamp = value ? value / 1000 : null;
    }
  }
};
</script>
<style lang="scss" scoped>
.order {
  width: 1200px;
  //   background-color: aqua;
  /deep/ .el-date-editor .el-range-separator {
    // box-sizing: border-box;
    width: 32px;
  }
  .filter {
    display: flex;
    align-items: center;
    position: relative;
  }
  .list {
    margin-top: 8px;
    display: flex;
  }
  .left {
    width: 395px;
    height: 667px;
    background: #ffffff;
    border-radius: 4px;
    margin-right: 8px;
    overflow: auto;
    .item {
      padding: 16px;
      // margin: 0 16px;
      border-bottom: 1px solid #f4f4f4;
      // height: 88px;
      .info {
        display: flex;
        .right {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 27px;
          width: 303px;
          padding: 0;
          .time {
            display: flex;
            .name {
              @include ellipsis;
              width: 85px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 27px;
              margin-right: 20px;
            }
            .creat_time {
              width: 189px;
              height: 14px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 27px;
              margin-right: -40px;
            }
          }
        }
      }
    }
  }
  .right {
    width: 790px;
    background-color: #fff;
    overflow: auto;
    padding: 16px;
    padding-bottom: 0px;
    line-height: 20px;
    font-size: 14px;
    border-radius: 5px;
    // height: 667px;
    .item_title {
      line-height: 28px;
    }
    .content {
      width: 757px;
      padding: 16px;
      // background-color: #f5f9ff;
      color: #666666;
      .title {
        color: #333333;
        line-height: 28px;
        display: flex;
        justify-content: space-between;
      }
      .query {
        line-height: 50px;
      }
      .query .after {
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: #409eff;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }
  .el-icon-video-play {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;
    font-size: 50px;
    color: #000000;
  }
}
div.popContainer {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}
.linHeight {
  height: 50px;
  line-height: 50px;
}
.activeColor {
  color: rgb(64, 158, 255);
}
</style>
